class DateTimePicker {
    static classes = {
        calentimHead: 'calentim-head',
        calentimHeadYear: 'calentim-head-year',
        calentimCalendars: 'calentim-calendars',
        calentimDay: 'calentim-day',
        calentimEvents: 'calentim-events',
        calentimEventsTooltip: 'calentim-events-tooltip',
        calentimEventContainer: 'calentim-event-container',
        calendarEventInfo: 'calendar-event-info',
        calendarEventIndicator: 'calendar-event-indicator',
        yearButton: 'btn-select-year',
        yearButtonSpan: 'year',
        hasEvent: 'has-event',
    };

    /**
     * @see DateTimePicker.classes
     */
    static selectors = GeneralUtility.getSelectors(DateTimePicker.classes);

    static defaultConfig = {
        calendarCount: function() {
            if (GeneralUtility.isMobile()) {
                return 1;
            }
            return 3;
        },
        inline: true,
        singleDate: true,
        showHeader: false,
        enableMonthSwitcher: true,
        monthSwitcherFormat: 'MMM',
        enableYearSwitcher: true,
        showWeekNumbers: true,
        showTimePickers: false,
        startOnMonday: true,
        withYearView: false,
        yearViewConfig: {},
        preInit: function (calentim) {},
        postInit: function (calentim) {},
        preAfterSelect: function (calentim, startDate, endDate) {},
        postAfterSelect: function (calentim, startDate, endDate) {},
    };

    calendarEvents = {};

    calentimConfig = {};

    $handler = null;
    $handlerContainer = null;
    calentim = null;
    eventsUrl = null;

    yearViewCalentim = null;
    parentCalentim = null;

    eventDataHasChanged = true;
    activeStartMonth = null;

    wasMobile = GeneralUtility.isMobile();

    /**
     * Constructor
     * @param $handler
     * @param calentimConfig
     * @param eventsUrl
     */
    constructor($handlerParam, calentimConfig, eventsUrl = null, parentCalentim = null) {
        this.$handler = $handlerParam;
        this.$handlerContainer = this.$handler.parent();
        this.parentCalentim = parentCalentim;

        this.eventsUrl = eventsUrl;
        this.calentimConfig = $.extend({}, DateTimePicker.defaultConfig, calentimConfig);
        this.addCalentimEventsToConfig();
        new Event().on('resize', window, this.resizeEvent.bind(this));
        new Event().on('click', DateTimePicker.selectors.calentimDay, function() {
            if (!$(this).hasClass(DateTimePicker.classes.hasEvent)) {
                $(DateTimePicker.selectors.calentimEventsTooltip).remove();
            }
        });

        if (eventsUrl !== null) {
            this.addEventsFromUrl(eventsUrl);
        } else {
            this.$handler.calentim(this.calentimConfig);
        }
    }

    addEventsFromUrl(eventsUrl) {
        let self = this;

        $.ajax({
            url: eventsUrl,
            context: this,
            success: this.ajaxSuccess,
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(jqXHR, textStatus, errorThrown);
            },
            complete: this.ajaxComplete
        });
    }

    ajaxSuccess(data, textStatus, jqXHR) {
        this.eventDataHasChanged = true;
        this.initializeEvents(data);
    }

    ajaxComplete() {
        this.$handler.calentim(this.calentimConfig);
    }

    /**
     *
     */
    addCalentimEventsToConfig() {
        this.calentimConfig.oninit = function (calentim) {
            this.calentimConfig.preInit(calentim);

            this.init(calentim);

            this.calentimConfig.postInit(calentim);
        }.bind(this);

        this.calentimConfig.onafterselect = function (calentim, startDate, endDate) {
            this.calentimConfig.preAfterSelect(calentim, startDate, endDate);

            this.afterSelect(calentim, startDate, endDate);

            this.calentimConfig.postAfterSelect(calentim, startDate, endDate);
        }.bind(this);

        this.calentimConfig.onbeforehide = this.checkFokus;
    }

    /* Prevent closing if user clicked inside a calendar event */
    checkFokus(calentim, target) {
        return !($(target).parents('.calendar-event-info').length > 0);
    }

    /**
     * @param {Array} events
     */
    initializeEvents(events) {
        for (let i = 0; i < events.length; i++) {
            let date = moment(events[i].dateTimeStart).format('YYYYMMDD');
            if (typeof this.calendarEvents[date] === 'undefined') {
                this.calendarEvents[date] = [];
            }
            this.calendarEvents[date].push(events[i]);
        }

        this.calentimConfig.ondraw = this.addCalentimEvents.bind(this);
    }

    addCalentimEvents(calentim) {
        let calendarCount = calentim.getCalendarCount();
        let displayStart = calentim.globals.currentDate.clone().startOf('month');
        let displayEnd = displayStart.clone().add(calendarCount, 'M').endOf('month');
        let eventsStart = parseInt(displayStart.format('YYYYMMDD'));
        let eventsEnd = parseInt(displayEnd.format('YYYYMMDD'));

        let eventDates = Object.keys(this.calendarEvents);

        this.removeEvents(calentim);
        for (let i = 0; i < eventDates.length; i++) {
            let eventDate = parseInt(eventDates[i]);
            if (eventDate >= eventsStart && eventDate <= eventsEnd) {
                this.addEventsToView(
                    calentim, this.calendarEvents[eventDates[i]]);
            }
        }
        this.eventDataHasChanged = false;
        this.activeStartMonth = eventsStart;

        // refresh year in button
        this.$handlerContainer
            .find(`${DateTimePicker.selectors.yearButton} span${DateTimePicker.selectors.yearButtonSpan}`)
            .text(calentim.globals.currentDate.year());

        if (GeneralUtility.isMobile()) {
            let calentimContainer = calentim.container;
            calentimContainer.find(DateTimePicker.selectors.calentimEventContainer).hide();
            new Event().on('click', calentimContainer.find(DateTimePicker.selectors.calentimDay), function(event) {
                let timestamp = $(this).data('value');
                calentimContainer.find(DateTimePicker.selectors.calentimEventContainer).hide();
                calentimContainer.find('.calentim-event-container[data-timestamp=' + timestamp + ']').show();
            });
        }
    }

    /**
     * @param calentim
     */
    removeEvents(calentim) {
        calentim.container.next().find(DateTimePicker.selectors.calentimCalendars).find(DateTimePicker.selectors.calentimDay).each(function(index, element) {
            let $element = $(element);
            if ($.tooltipster.instances($element).length > 0) {
                $element.tooltipster('disable');
            }
            $element.find(DateTimePicker.selectors.calendarEventIndicator).remove();
            $element.removeClass(DateTimePicker.classes.hasEvent);
        }.bind(this));
        calentim.container.find(DateTimePicker.selectors.calentimEvents).html('');
    }

    /**
     * @param {calentim} calentim
     * @param {Array} events
     */
    addEventsToView(calentim, events) {
        for (let i=0; i < events.length; i++) {
            let eventStart = moment(events[i].dateTimeStart).startOf('day'),
                eventEnd = moment(events[i].dateTimeEnd).startOf('day');

            do {
                let eventDay = eventStart.clone(),
                    cellDate = eventDay.middleOfDay().unix(),
                    $cells = calentim.container.find(DateTimePicker.selectors.calentimCalendars).find('[data-value="' + cellDate + '"]');

                let self = this;

                $.each($cells, function(index, cell) {
                    let $cell = $(cell);
                    let accordionId = GeneralUtility.uniqueId();

                    if (!$cell.is(DateTimePicker.selectors.hasEvent)) {
                        $cell.append('<span class="calendar-event-indicator"></span>');

                        let $eventDropdown = $('<div class="calendar-event-info-wrapper accordion" id="' + accordionId + '"></div>');
                        if (!GeneralUtility.isMobile()) {
                            if ($.tooltipster.instances($cell).length > 0) {
                                $cell.tooltipster('enable');
                                $cell.data($cell.data('tooltipsterNs')[0])['__Content'].empty();
                            } else {
                                $cell.tooltipster({
                                    content: $eventDropdown,
                                    interactive: true,
                                    trigger: 'click',
                                    theme: 'calentim-events-tooltip',
                                    side: ['bottom', 'top'],
                                    functionBefore: function (event) {
                                        $('.calentim-events-tooltip').remove();
                                    },
                                });
                            }
                        }
                        $cell.addClass(DateTimePicker.classes.hasEvent + ' dropdown-toggle-hover');
                    }

                    if (!GeneralUtility.isMobile()) {
                        let $eventInfoAccordion = $cell.data($cell.data('tooltipsterNs')[0])['__Content'];
                        let eventInfo = self.createEventInfo(events[i], $eventInfoAccordion.attr('id'));
                        $eventInfoAccordion.append(eventInfo);
                    } else {
                        let $calenderEventsContainer = calentim.container.find(DateTimePicker.selectors.calentimEvents);
                        $calenderEventsContainer.append('<div class="calentim-event-container" id="calentim-event-' + accordionId +'" data-timestamp="' + cellDate + '">'
                            + '<div class="calentim-event-box">'
                            + '<div class="calentim-event-content">'
                            + self.createEventInfo(events[i], 'calentim-event-' + accordionId)
                            + '</div>'
                            + '</div>'
                            + '</div>');
                    }
                });

            } while (eventStart.add(1, 'days').diff(eventEnd) <= 0);

        }
    }

    /**
     *
     * @param {Object} event
     * @param {string} parentId
     * @returns {html}
     */
    createEventInfo(event, parentId) {
        let eventStartDate = moment(event.dateTimeStart);
        let eventEndDate = moment(event.dateTimeEnd);
        let eventDate = eventStartDate.format('L');
        if (eventStartDate.clone().startOf('day').diff(eventEndDate.clone().startOf('day')) < 0) {
            eventDate += ' - ' + eventEndDate.format('L');
        }
        let eventTime = eventStartDate.format('LT') + ' - ' + eventEndDate.format('LT');

        let collapseBodyId = GeneralUtility.uniqueId();

        let template = `<div class="calendar-event-info">
            <div class="calendar-event-info-header">
                <div class="row align-items-center">
                    <div class="col-4 event-datetime">
                        <span class="event-date">${eventDate}</span>
                        <span class="event-time d-block tupper-12 tupper-grey">${eventTime}</small>
                    </div>
                    <div class="col event-description">
                        <span class="event-title">${event.title}</span>
                        <small class="event-subtitle d-block tupper-12 tupper-grey">${event.subtitle}</small>
                    </div>
                    <div class="col-auto"><span class="content-toggle" data-toggle="collapse" data-target="#${collapseBodyId}" aria-controls="${collapseBodyId}"></span></div>
                </div>
            </div>
            <div class="calendar-event-info-content-wrapper collapse" data-parent="#${parentId}" id="${collapseBodyId}">${event.html}</div>
        </div>`.trim();

        return template;
    }

    afterSelect(calentim, startDate, endDate) {
        if (this.yearViewCalentim !== null) {
            // do something
        }
    }

    init(calentim) {
        this.calentim = calentim;

        if (this.calentimConfig.withYearView === true) {
            this.yearViewCalentim = new YearViewPicker(
                this.calentimConfig.yearViewConfig,
                this.eventsUrl,
                this
            );
        } else {
            this.$handler.parent().siblings(DateTimePicker.selectors.calentimHead).find(DateTimePicker.selectors.calentimHeadYear).html(`
                <button type="button" class="btn btn-secondary btn-icon btn-small float-right ${DateTimePicker.classes.yearButton}" disabled>
                    <span class="icon--calendar-thick icon--small"></span><span class="${DateTimePicker.classes.yearButtonSpan}">${moment().format('YYYY')}</span>
                </button>
            `);
        }
    }

    resizeEvent(event) {
        if (this.calentim === null) return;

        if ((!this.wasMobile && GeneralUtility.isMobile()) || (this.wasMobile && !GeneralUtility.isMobile())) {
            this.reDrawCalendars();
        }
        this.wasMobile = GeneralUtility.isMobile();
    }

    redrawCalendar() {
        this.calentim.reDrawCalendars();
    }

    setDate(newStartDate, newEndDate) {
        this.calentim.setStart(newStartDate);
        this.calentim.setEnd(newEndDate);

        let calendarCount = this.calentim.getCalendarCount();
        let monthAddition = Math.floor(calendarCount / 2) - 1;
        let showMonth = newStartDate.clone().add(monthAddition, 'M');
        this.eventDataHasChanged = true;
        this.calentim.setDisplayDate(showMonth);

        // refresh year in btn-select-year object
        this.$handlerContainer
            .find(`${DateTimePicker.selectors.yearButton} span${DateTimePicker.selectors.yearButtonSpan}`)
            .text(this.calentim.globals.currentDate.year());
    }
}