class TupperTable {
    static handlerSelector = '.tuppertable';

    static classes = {
        filtersWrapper: 'tuppertable-filters',
        table: 'data-table',

        actionButtonsColumn: 'action-buttons-column',

        // search element classes
        filterSearchInput: 'datatables-search-input',
        filterSearchInputText: 'search-input-text',
        filterClearSearch: 'clear-search',

        // filter element classes
        filterToggleWrapper: 'filter-wrapper',
        filterInfo: 'filter-info',
        filterInfoItem: 'filter-info-item',
        filterInfoItemResetButton: 'filter-info-item-reset-button',
        filterToggle: 'toggle-filter-button',
        filterNoSelectedOption: 'no-selected-option',
        concreteFilterWrapper: 'datatables-data-filter-details',
        concreteFilter: 'table-filter',

        // pagination element classes
        paginationButton: 'pagination-length-button',
        paginationLengthWrapper: 'dataTables_pagination-length-wrapper',
        paginationCollection: 'pagination-length-collection',
        paginationLengthCollectionSelection: 'pagination-length-collection-selection',

        mobileToggleButton: 'dtr-header-close',

        rowSelector: 'datatable-expandable-row'
    };

    /**
     * @see TupperTable.classes
     */
    static selectors = GeneralUtility.getSelectors(TupperTable.classes);

    /**
     * Whether or not the first column is a "state" column, i.e. has a checkbox or an icon, or ...
     * @type {boolean}
     */
    hasPrependedStateColumn = false;

    dataTablesOriginalCells = {};

    /**
     * @type DataTable
     */
    dataTable = null;

    /**
     * @type {{}}
     */
    config = {};

    static initClearPipeline() {
        $.fn.dataTable.Api.register( 'clearPipeline()', function () {
            return this.iterator( 'table', function ( settings ) {
                settings.clearCache = true;
            } );
        } );
    }

    constructor($handler, config) {
        this.$handler = $handler;

        this.$filters =  this.$handler.find(TupperTable.selectors.filtersWrapper);
        this.$table = this.$handler.find(TupperTable.selectors.table);
        this.config = config;

        if (this.config.ajax === true) {
            this.initAjax();
        }

        this.initTable();
        this.initEvents();
    }

    initAjax() {
        if (this.config.pipelining === true) {
            TupperTable.initClearPipeline();
            this.config.serverSide = true;
            this.config.processing = true;

            this.config.ajax = this.dataTablePipeline({
                url: this.config.ajaxUrl,
                method: this.config.method,
                pages: this.config.cachedPages,
                data: this.config.pipelineData || null,
            });
        } else {
            this.config.ajax = {
                url: this.config.ajaxUrl,
                contentType: "application/json",
                serverSide: true,
                processing: true,
                type: this.config.method,
                data: (data) => {
                    return JSON.stringify(data);
                },
                dataFilter: (data) => {
                    let json = jQuery.parseJSON( data );
                    return JSON.stringify( json.result );
                },
            };
        }
    }

    initTable() {
        this.dataTable = this.$table.DataTable($.extend(true, {
            // default settings
            searching: true,
            paging: true,
            pagingType: 'numbers',
            iDisplayLength: -1,
            ordering: true,
            info: false,
            dom: 'frtip',
            autoWidth: false,
            search: {
                date: [],
            },
            expandable: true,
            expandableContainer: '.datatable-extendable-container',
            responsive: {
                details: {
                    type: 'column',
                    target: -1,
                    renderer: this.responsiveRenderer.bind(this)
                },
                breakpoints: [
                    {name: 'desktop', width: Infinity},
                    // Since dataTables.responsive.js is a pile of garbage, we have to
                    // calculate the size of the vertical scrollbar and add it to the viewport size
                    {name: 'tablet',  width: 991 + $(window).width() - $(window).outerWidth()},
                    {name: 'fablet',  width: 767 + $(window).width() - $(window).outerWidth()},
                    {name: 'phone',   width: 575 + $(window).width() - $(window).outerWidth()}
                ]
            },
            drawCallback: this.tableDrawCallback.bind(this),
            columnDefs: [ {
                className: 'control',
                orderable: false,
                targets:   -1
            } ],
            initComplete: this.initComplete.bind(this)
        }, this.config));
    }

    destroyTable() {
        this.dataTable.destroy();
    }

    initEvents() {
        // search events
        new Event().on('click', this.$filters.find(TupperTable.selectors.filterSearchInput), this.filterSearchInputEvent.bind(this));
        new Event().on('click', window, this.windowEvent.bind(this));
        new Event().on('keyup', this.$filters.find(TupperTable.selectors.filterSearchInputText), this.filterSearchInputTextEvent.bind(this));
        new Event().on('keypress', this.$filters.find(TupperTable.selectors.filterSearchInputText), this.filterSearchInputTextPressEvent.bind(this));
        new Event().on('click', this.$filters.find(TupperTable.selectors.filterClearSearch), this.filterClearSearchEvent.bind(this));

        // filter events
        new Event().on('click', this.$handler.find(TupperTable.selectors.filterToggle), this.filterToggleEvent.bind(this));
        new Event().on('submit', this.$handler.find(TupperTable.selectors.concreteFilter), this.filterSubmit.bind(this));
        new Event().on('reset', this.$handler.find(TupperTable.selectors.concreteFilter), this.filterReset.bind(this));
        new Event().on('change', this.$handler.find(TupperTable.selectors.concreteFilterWrapper).find('select'), this.filterSetSelectClass.bind(this), {init: true});

        // pagination events
        new Event().on('click', this.$handler.find(TupperTable.selectors.paginationLengthCollectionSelection),
            this.paginationCollectionEvent.bind(this));

        // mobile toggle button events
        new Event().on('click', TupperTable.selectors.mobileToggleButton, this.mobileToggleButtonEvent.bind(this), {live: true, bindObject: this.$handler});
        new Event().on('resize', window, this.windowResizeEvent.bind(this), {init: true});

        new Event().on('click', this.$handler.find(TupperTable.selectors.rowSelector), this.rowClickEvent.bind(this));
    }

    dataTablePipeline(options) {
        // Configuration options
        var conf = $.extend( {
            pages: 5,     // number of pages to cache
            url: '',      // script url
            data: null,   // function or object with parameters to send to the server
                          // matching how `ajax.data` works in DataTables
            method: 'GET' // Ajax HTTP method
        }, options );

        // Private variables for storing the cache
        var cacheLower = -1;
        var cacheUpper = null;
        var cacheLastRequest = null;
        var cacheLastJson = null;

        return function ( request, drawCallback, settings ) {
            var ajax          = false;
            var requestStart  = request.start;
            var drawStart     = request.start;
            var requestLength = request.length;
            var requestEnd    = requestStart + requestLength;

            if ( settings.clearCache ) {
                // API requested that the cache be cleared
                ajax = true;
                settings.clearCache = false;
            }
            else if ( cacheLower < 0 || requestStart < cacheLower || requestEnd > cacheUpper ) {
                // outside cached data - need to make a request
                ajax = true;
            }
            else if ( JSON.stringify( request.order )   !== JSON.stringify( cacheLastRequest.order ) ||
                JSON.stringify( request.columns ) !== JSON.stringify( cacheLastRequest.columns ) ||
                JSON.stringify( request.search )  !== JSON.stringify( cacheLastRequest.search )
            ) {
                // properties changed (ordering, columns, searching)
                ajax = true;
            }

            // Store the request for checking next time around
            cacheLastRequest = $.extend( true, {}, request );

            if ( ajax ) {
                // Need data from the server
                if ( requestStart < cacheLower ) {
                    requestStart = requestStart - (requestLength*(conf.pages-1));

                    if ( requestStart < 0 ) {
                        requestStart = 0;
                    }
                }

                cacheLower = requestStart;
                cacheUpper = requestStart + (requestLength * conf.pages);

                request.start = requestStart;
                request.length = requestLength*conf.pages;

                // Provide the same `data` options as DataTables.
                if ( typeof conf.data === 'function' ) {
                    // As a function it is executed with the data object as an arg
                    // for manipulation. If an object is returned, it is used as the
                    // data object to submit
                    var d = conf.data( request );
                    if ( d ) {
                        $.extend( request, d );
                    }
                }
                else if ( $.isPlainObject( conf.data ) ) {
                    // As an object, the data given extends the default
                    $.extend( request, conf.data );
                }

                settings.jqXHR = $.ajax( {
                    "type":     conf.method,
                    "url":      conf.url,
                    "data":     JSON.stringify( request),
                    "dataFilter": (data) => {
                        let json = jQuery.parseJSON( data );
                        return JSON.stringify( json.result );
                    },
                    "dataType": "json",
                    "contentType": "application/json",
                    "cache":    false,
                    "success":  function ( json ) {
                        cacheLastJson = $.extend(true, {}, json);

                        if ( cacheLower != drawStart ) {
                            json.data.splice( 0, drawStart-cacheLower );
                        }
                        if ( requestLength >= -1 ) {
                            json.data.splice( requestLength, json.data.length );
                        }

                        drawCallback( json );
                    }
                } );
            }
            else {
                json = $.extend( true, {}, cacheLastJson );
                json.draw = request.draw; // Update the echo for each response
                json.data.splice( 0, requestStart-cacheLower );
                json.data.splice( requestLength, json.data.length );

                drawCallback(json);
            }
        }
    }

    rowClickEvent(e) {
        if ($(e.target).hasClass('btn') || $(e.target).parent('.btn').length) {
            return;
        }

        if ($(e.currentTarget).next().hasClass('datatable-extendable-container')) {
            $('.datatable-extendable-container').slideUp(400);
            $(e.currentTarget).removeClass('datatable-expandable-highlighted-row');
            $('#page-overlay').hide();
        } else {
            $('.datatable-extendable-container').insertAfter(e.currentTarget).slideDown(400);
            $(e.currentTarget).addClass('datatable-expandable-highlighted-row');
            $('#page-overlay').show();
        }
    }

    /**
     * Show or hide pagination select when no matching records were found
     *
     * @param settings
     */
    tableDrawCallback(settings) {
        let api = new $.fn.dataTable.Api(settings);
        let hasVisibleRows = api.rows({page: 'current'})[0].length > 0;

        $(TupperTable.selectors.paginationLengthWrapper)[hasVisibleRows ? 'removeClass' : 'addClass']('d-none');
    }

    filterSearchInputEvent(event) {
        event.stopPropagation();

        let $target = $(event.currentTarget);
        let $targetText = $target.find(TupperTable.selectors.filterSearchInputText);
        $target.addClass('focus');

        if (!$targetText.length) {
            return;
        }
        GeneralUtility.placeCaretAtEnd($targetText.get(0));
    }

    windowEvent(event) {
        this.$filters.find(TupperTable.selectors.filterSearchInput).removeClass('focus');
        if (!this.$filters.find(TupperTable.selectors.filterSearchInput).is('.not-empty')) {
            this.$filters.find(TupperTable.selectors.filterClearSearch).css({
                opacity: '0',
                visibility: 'hidden'
            });
        }
    }

    windowResizeEvent(event) {
        let self = this;
        this.windowEvent();

        if (!GeneralUtility.isMobile()) {
            let $ths = this.$table.find('thead').find('th, td');
            $ths.each(function () {
                if ($(this).hasClass('non-desktop')) {
                    $(this).addClass('never');
                }
            });
            this.dataTable.responsive.rebuild();
            this.dataTable.responsive.recalc();

            self.mobileToggleButtonEvent(null, true);
        } else {
            // change fixed widths
            let $ths = this.$table.find('thead').find('th, td');
            let $headerIndexElements = this.$table.find('tbody .dtr-header-index');
            let $contentIndexElements = this.$table.find('tbody .dtr-content-index');

            $ths.each(function () {
                if ($(this).hasClass('non-desktop')) {
                    $(this).removeClass('never');
                }
            });
            this.dataTable.responsive.rebuild();
            this.dataTable.responsive.recalc();

            let previousWidth = 0;
            $ths.each(function (index) {
                let width = $(this).outerWidth();

                $headerIndexElements.filter(`[data-index=${index}]`).css('width', width);
                if (self.hasPrependedStateColumn && index == 1) {
                    $contentIndexElements.filter(`[data-index=${index - 1}]`).css('flex-basis', `${width + previousWidth}px`);
                } else {
                    $contentIndexElements.filter(`[data-index=${index}]`).css('flex-basis', `${width}px`);
                }

                previousWidth = width;
            });
        }
    }

    filterSearchInputTextEvent(event) {
        let $target = $(event.currentTarget);

        if ($target.text().length > 0) {
            this.$filters.find(TupperTable.selectors.filterSearchInput).addClass('not-empty');
        } else {
            this.$filters.find(TupperTable.selectors.filterSearchInput).removeClass('not-empty');
        }

        this.dataTable.search($target.text()).draw();

        // show clear icon when text is not empty, hide it if text is empty
        $target.siblings(TupperTable.selectors.filterClearSearch).css(
            $target.text().length > 0
                ? {opacity: '1', visibility: 'visible'}
                : {opacity: '0', visibility: 'hidden'}
        );
    }

    filterSearchInputTextPressEvent(event) {
        //
        if (event.which === 10 || event.which === 13) {
            return false;
        }
    }

    filterClearSearchEvent(event) {
        let $target = $(event.currentTarget);

        $target.siblings(TupperTable.selectors.filterSearchInputText).text('').focus();
        this.dataTable.search($target.text()).draw();
        $target.css({
            opacity: '0',
            visibility: 'hidden'
        });
        $target.siblings(TupperTable.selectors.filterSearchInputText).trigger('keyup');
    }

    filterToggleEvent(event, bCloseOnly=false) {
        let $target = this.$handler.find(TupperTable.selectors.filterToggle);
        let $filterDetailsElement = this.$handler.find(TupperTable.selectors.concreteFilterWrapper);

        if (!bCloseOnly && !$filterDetailsElement.hasClass('show')) {
            this.$handler.find('.datatables-filter-arrow-wrapper').slideDown(50, function () {
                $filterDetailsElement.collapse('show');
            });
            $('.mobile-filter-button').addClass('filter-details-open');
        } else if ($filterDetailsElement.hasClass('show')) {
            new Event().one('hidden', $filterDetailsElement, function () {
                this.$handler.find('.datatables-filter-arrow-wrapper').slideUp(50);
            }.bind(this), {namespace: 'bs.collapse'});
            $filterDetailsElement.collapse('hide');
            $('.mobile-filter-button').removeClass('filter-details-open');
        }
    }

    paginationCollectionEvent(event) {
        let $target = $(event.currentTarget);
        let $paginationButton = $target.parent().siblings(TupperTable.selectors.paginationButton);

        $paginationButton.html($target.html());

        this.setPaginationLength($target.find('span').attr('data-pagination-length'));
    }

    mobileToggleButtonEvent(event=null, bCloseAll=false) {
        if (event === null && !bCloseAll) {
            console.log('This mode is not yet supported');
            return;
        }

        let thisClass = this;
        let $trs = bCloseAll ? this.$table.find('tr'): $(event.currentTarget).closest('tr');

        $trs.each(function () {
            let $tr = $(this);
            let row = thisClass.dataTable.row($tr);
            let firstCell = thisClass.dataTablesOriginalCells[row[0]];

            if (!firstCell)
                return true;

            // animate margin-top/bottom
            let duration = !bCloseAll ? 300 : 0;
            firstCell.jQueryObject.find('.dtr-wrapper').animate({
                'margin-top': '0',
                'margin-bottom': '0'
            }, duration, 'swing', function () {
                firstCell.jQueryObject.html(firstCell.data).attr('colspan', '');
                $tr.removeClass('parent');
                $tr.find('td.d-none').removeClass('d-none');
            });
        });
    }

    setPaginationLength(length) {
        this.dataTable.page.len(length);
        this.dataTable.draw();
    }

    responsiveRenderer(api, rowIdx, columns) {
        let self = this;
        let cells = {
            visible: {},
            hidden: {}
        };
        let highestColumnIndex = 0;

        $.each(columns, function (index, cell) {
            let $cell = api.cell({row: cell.rowIndex[0], column: cell.columnIndex}).nodes().toJQuery();
            if ($cell.find('.dtr-header-index[data-index=0]').length > 0) {
                cell.outerWidth = $cell.find('.dtr-header-index[data-index=0]').outerWidth();
                cell.jQueryObject = $cell;
            } else {
                cell.outerWidth = $cell.outerWidth();
                cell.jQueryObject = $cell;
            }

            if (highestColumnIndex < cell.columnIndex) {
                highestColumnIndex = cell.columnIndex;
            }

            if (cell.hidden) {
                cells.hidden[index] = cell;
            } else {
                cells.visible[index] = cell;
            }
        });

        // hide visible cells, except the first one
        let firstCell = null;
        let secondCell = null;
        $.each(cells.visible, function (index, cell) {
            if (firstCell === null) {
                // change colspan of first visible cell
                cell.jQueryObject.attr('colspan', Object.keys(cells.visible).length + 1);

                self.dataTablesOriginalCells[rowIdx[0]] = cell;
                firstCell = cell;
            } else if (secondCell === null) {
                secondCell = cell;
                cell.jQueryObject.addClass('d-none');
            } else {
                cell.jQueryObject.addClass('d-none');
            }
        });

        // hide toggle button cell
        highestColumnIndex++;
        let $toggleButtonCell = api.cell({row: rowIdx[0], column: highestColumnIndex}).nodes().toJQuery();
        $toggleButtonCell.addClass('d-none');

        // add content to first cell
        cells = this.cellsProcessor(cells);
        let data = this.dataRenderer(cells, firstCell, secondCell);
        firstCell.jQueryObject.html(data);

        // animate margin-top/bottom
        firstCell.jQueryObject.find('.dtr-wrapper').animate({
            'margin-top': '1.25rem',
            'margin-bottom': '1.25rem'
        }, 300);

        return false;
    }

    initComplete(settings) {
        let $paginationButton = this.$handler.find(TupperTable.selectors.paginationButton);
        let $selectedElement = this.$handler.find(TupperTable.selectors.paginationLengthCollectionSelection).first();
        $paginationButton.html($selectedElement.html());

        // cannot use this.setPaginationLength here, as this.dataTable is not yet available
        let api = new $.fn.dataTable.Api(settings);
        api.page.len($selectedElement.find('span').attr('data-pagination-length'));

        if (typeof window.dataTableRows !== 'undefined' && this.constructor.name in window.dataTableRows) {
            $.each(window.dataTableRows[this.constructor.name], function (index, value) {
                api.row.add(value);
            });
        }
        api.draw();
    }

    cellsProcessor(cells) {
        return cells;
    }

    dataRenderer(cells, firstCell, secondCell) {
        let dataHeader = $.map(cells.visible, function (cell) {
            let textClass = cell.jQueryObject.hasClass('text-left')
                ? 'text-left'
                : (cell.jQueryObject.hasClass('text-center')
                    ? 'text-center'
                    : (cell.jQueryObject.hasClass('text-right') ? 'text-right' : '')
                );

            return `<div class="dtr-header-index ${textClass}"  data-index="${cell.columnIndex}" style="width: ${cell.outerWidth}px">
                ${cell.data}
            </div>`;
        }).join('');

        let hasPrependedStateColumn = this.hasPrependedStateColumn;
        let dataBody = $.map(cells.hidden, function (column) {
            let $th = column.jQueryObject.closest('table').find('th:nth-child(' + (column.columnIndex + 1) + ')');
            if (column.title == '' || $th.hasClass(TupperTable.classes.actionButtonsColumn)) {
                return `<div class="dtr-content-edit-wrapper">
                            <div class="dtr-data">${column.data}</div>
                        </div>`;
            } else {
                let width = hasPrependedStateColumn ? firstCell.outerWidth + secondCell.outerWidth : firstCell.outerWidth;
                return `<div class="dtr-content-wrapper">
                            <div
                                class="dtr-title dtr-content-index"
                                data-index="${firstCell.columnIndex}"
                                style="flex-basis: ${width}px"
                            >
                                ${column.title}
                            </div>
                            <div class="dtr-data">${column.data}</div>
                        </div>`;
            }
        }).join('');

        return `<div class="dtr-wrapper">
                    <div class="dtr-header">
                        ${dataHeader}
                        <div class="${TupperTable.classes.mobileToggleButton} ml-auto">
                            <span class="icon--chevron_up tupper-black"></span>
                        </div>
                    </div>
                    <div class="dtr-body">
                        ${dataBody}
                    </div>
                </div>`;
    }

    filterSubmit(event) {
        // TODO implement this in derived class
        console.log("Filtering is not implemented");
    }

    filterReset(event) {
        let $target = $(event.currentTarget);

        this.dataTable.columns().search('').draw();

        window.setTimeout(function () { $target.find('select, input').trigger('change'); });
    }

    filterRemove(event) {
        let $filterInfoItem = $(event.currentTarget).closest(TupperTable.selectors.filterInfoItem);
        $filterInfoItem.detach();

        let elements = event.data;
        if (!(elements instanceof Array)) {
            elements = [elements];
        }

        $.each(elements, function (index, $element) {
            $element.is('textarea') ? $element.text('') : $element.val('');
        });

        this.filterSubmit(event);
    }

    filterSetSelectClass(event) {
        let $self = $(event.currentTarget);

        $self[$self.find('option:selected').is(':disabled') ? 'addClass' : 'removeClass'](
            TupperTable.classes.filterNoSelectedOption
        );
    }
}