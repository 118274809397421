class YearViewPicker extends DateTimePicker {
    static classes = $.extend(DateTimePicker.classes, {
        calentimHead: 'calentim-head',
        calentimHeadYear: 'calentim-head-year',
        calentimYearPrev: 'calentim-year-prev',
        calentimYearNext: 'calentim-year-next',
        calentimYearCurrent: 'calentim-year-current',
        datePickerModalClose: 'datepicker-modal-close',
    });

    /**
     * @see YearViewPicker.classes
     */
    static selectors = GeneralUtility.getSelectors(YearViewPicker.classes);

    static defaultConfig = {
        calendarCount: 12,
        showFooter: false,
    };

    $modal = null;

    constructor(calentimConfig, eventsUrl = null, parent) {
        let returnValues = YearViewPicker.addHtml(parent);
        let $handler = returnValues.handler;
        super($handler, $.extend({}, YearViewPicker.defaultConfig, calentimConfig), eventsUrl, parent);

        this.$modal = returnValues.modal;
        new Event().on('show', this.$modal, this.showModalEvent.bind(this), {namespace: 'bs.modal'});
        new Event().on('click', this.$modal.find(YearViewPicker.selectors.datePickerModalClose), this.syncDate.bind(this));

        this.addYearViewEvents();
    }

    static addHtml(parent) {
        let handlerId = GeneralUtility.uniqueId();
        let modalId = GeneralUtility.uniqueId();

        parent.$handlerContainer.append(`<div class="modal fade" id="calentim-year-${modalId}-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-full" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close ${YearViewPicker.classes.datePickerModalClose}" data-dismiss="modal" aria-label="Close">
                            <span class="icon--close tupper-black"></span>
                            <span class="close-text">Close</span>
                        </button>
                        <h3 class="d-inline-block ${YearViewPicker.classes.calentimYearCurrent}">${moment().format('YYYY')}</h3>
                        <span class="icon--back_arrow tupper-black ${YearViewPicker.classes.calentimYearPrev}"></span>
                        <span class="icon--back_arrow icon-rotate-180 tupper-black ${YearViewPicker.classes.calentimYearNext}"></span>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body-content container">
                            <input id="calentim-year-${handlerId}" type="text" name="calendar-year-input" />
                        </div>
                    </div>
                </div>
            </div>
        </div>`);

        parent.$handlerContainer.find(YearViewPicker.selectors.calentimHeadYear).html(`
            <button type="button" class="btn btn-primary btn-icon btn-small float-right ${YearViewPicker.classes.yearButton}" data-toggle="modal" data-target="#calentim-year-${modalId}-modal">
                <span class="icon--calendar-thick icon--small"></span><span class="${YearViewPicker.classes.yearButtonSpan}">${moment().format('YYYY')}</span>
            </button>
        `);

        return {
            handler: $(`#calentim-year-${handlerId}`),
            modal: $(`#calentim-year-${modalId}-modal`),
        };
    }

    addYearViewEvents() {
        this.$modal.find(YearViewPicker.selectors.calentimYearPrev).on('click', function() {
            this.calentim.globals.currentDate.middleOfDay().subtract(12, "month");
            this.calentim.reDrawCalendars();
            this.$modal.find(YearViewPicker.selectors.calentimYearCurrent).text(this.calentim.globals.currentDate.middleOfDay().year())
        }.bind(this));
        this.$modal.find(YearViewPicker.selectors.calentimYearNext).on('click', function() {
            this.calentim.globals.currentDate.middleOfDay().add(12, "month");
            this.calentim.reDrawCalendars();
            this.$modal.find(YearViewPicker.selectors.calentimYearCurrent).text(this.calentim.globals.currentDate.middleOfDay().year())
        }.bind(this));
    }

    showModalEvent(event) {
        let startDate = this.parentCalentim.calentim.config.startDate,
            endDate = this.parentCalentim.calentim.config.endDate,
            year = this.parentCalentim.calentim.globals.currentDate.year();

        this.calentim.setStart(startDate);
        this.calentim.setEnd(endDate);
        this.eventDataHasChanged = true;
        this.calentim.setDisplayDate(moment(year + '-01', 'YYYY-MM'));

        this.$modal.find(YearViewPicker.selectors.calentimYearCurrent).html(year);
    }

    syncDate() {
        let newStartDate = this.calentim.config.startDate,
            newEndDate = this.calentim.config.endDate;

        this.parentCalentim.setDate(newStartDate, newEndDate);
    }

    init(calentim) {
        this.calentim = calentim;
        calentim.setDisplayDate(this.parentCalentim.calentim.globals.currentDate.clone().month(0));
    }
}