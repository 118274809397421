class GeneralUtility {
    static isMobile() {
        return $(window).outerWidth() < 992;
    }

    static placeCaretAtEnd(element) {
        element.focus();
        if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(element);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(element);
            textRange.collapse(false);
            textRange.select();
        }
    }

    /**
     * @param classes objectArray of classNames
     * @return object
     */
    static getSelectors(classes) {
        let selectors = {};
        $.each(classes, function (index, value) {
            selectors[index] = '.' + value;
        });

        return selectors;
    }

    /**
     * Creates and returns a random string that qualifies as unique id for html elements
     *
     * @returns {string}
     */
    static uniqueId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    static getLanguageCode() {
        return $('html').attr('lang');
    }

    static getCountryCode() {
        return $('html').attr('data-country').toUpperCase();
    }

    static getLocale() {
        return GeneralUtility.getLanguageCode() + '_' + GeneralUtility.getCountryCode();
    }
}