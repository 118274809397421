moment.locale(GeneralUtility.getLanguageCode());

$(document).ready(function () {
    let isMobile = $(window).outerWidth() < 1200;


    // Change size of header when sticky
    // When the user scrolls down 60px/90px from the top of the document, resize the header's font size
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (isMobile) {
            if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 90) {
                $('header').addClass('sticky')
            } else {
                $('header').removeClass('sticky');
            }
        } else {
            if (document.body.scrollTop > 90 || document.documentElement.scrollTop > 90) {
                $('header').addClass('sticky')
            } else {
                $('header').removeClass('sticky');
            }
        }
    }


    // Initialize tooltips
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    //Initialize Calentim input
    $(".calendar-input").calentim({
        startEmpty: true,
        calendarCount: 1,
        showHeader: false,
        showFooter: false,
        singleDate: true,
        showTimePickers: false,
        startOnMonday: true,
        showWeekNumbers: true,
        format: 'YYYY-MM-DD',
    });

    $('.sub-navigation').each(function(index, subNavigationElement) {
        new HorizontalNavigation($(subNavigationElement));
    });

    let bFirstTouched = false;
    $('a[href*="#"]').on('click touchend', function(e) {
        if ($(this).closest('#main-navigation').length > 0 && e.type !== 'click') {
            bFirstTouched = true;
            return;
        } else if (bFirstTouched) {
            return;
        } else {
            bFirstTouched = false;
        }
        if ($(this).is('.dropdown-toggle') && isMobile) {
            return;
        }
        e.preventDefault();
        var $target = $(this.hash);
        if (!$target.length || $target.css('display') == 'none')
            return;

        $('html, body').animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function () {
            // window.location.hash = target;
        });
    });


    // If a selected option of a select dropdown is disabled, add class to select
    $('select').on('change', function (event) {
        let $self = $(event.currentTarget);
        $self[$self.find('option:selected').is(':disabled') ? 'addClass' : 'removeClass']('no-selected-option');
    });


   /* $('#main-navigation .nav-item').bind('touchstart', function (event) {
        if ($(this).hasClass('dropdown')) {
            event.preventDefault();
        }
    });*/

    let mainNavigation = new Navigation();

});
