class Navigation {
    static isMobileNavigation() {
        return $(window).outerWidth() < 1200;
    }

    static classes = {
        navbarToggler: 'navbar-toggler',
        subMenuWrapper: 'dropdown',
        subMenuWrapperDropdownOpen: 'dropdown-menu-open',
        subMenu: 'dropdown-menu',
        subMenuLink: 'dropdown-toggle',
        subMenuToggle: 'dropdown-toggle-button',

        navbarMenuOpened: 'menu-opened',
        navbarSubmenuOpened: 'submenu-opened',

        languageMenuButton: 'language-navigation-button',
        languageMenuOpen: 'language-menu-open'
    };
    static selectors = {
        navbarToggler: '.' + Navigation.classes.navbarToggler,
        subMenuWrapper: '.' + Navigation.classes.subMenuWrapper,
        subMenuWrapperDropdownOpen: '.' + Navigation.classes.subMenuWrapperDropdownOpen,
        subMenu: '.' + Navigation.classes.subMenu,
        subMenuLink: '.' + Navigation.classes.subMenuLink,
        subMenuToggle: '.' + Navigation.classes.subMenuToggle,

        navbarMenuOpened: '.' + Navigation.classes.navbarMenuOpened,
        navbarSubmenuOpened: '.' + Navigation.classes.navbarSubmenuOpened,

        languageMenuButton: '.' + Navigation.classes.languageMenuButton,

        navbar: '#head-navigation',
        mainNavigation: '#main-navigation',
        languageMenu: '#language-navigation'
    };

    constructor() {
        this.navigation = $(Navigation.selectors.navbar);
        this.mobileToggle = this.navigation.find(Navigation.selectors.navbarToggler + '[data-target="' + Navigation.selectors.mainNavigation + '"]');

        this.firstLevel = this.navigation.find(Navigation.selectors.mainNavigation);

        new Event().on('resize', window, this.onResize.bind(this), {init: true});

        new Event().on('click', this.mobileToggle, this.mobileNavToggleClick.bind(this));

        new Event().on('click', Navigation.selectors.navbar + ' ' + Navigation.selectors.subMenuLink, function(event) {
            event.preventDefault();
            let $subMenu = $(event.target).closest(Navigation.selectors.subMenuWrapper).find(Navigation.selectors.subMenu);
            this.openSubmenu($subMenu);
        }.bind(this));

        new Event().on('click', this.navigation.find('.nav-link:not(' + Navigation.selectors.subMenuLink + '), .dropdown-item'), this.closeMenu.bind(this));
        new Event().on('click', this.navigation.find(Navigation.selectors.languageMenuButton), this.languageNavigationToggle.bind(this));
        new Event().on('click', this.navigation.find(Navigation.selectors.languageMenu), this.languageNavigationClickEvent.bind(this));
        new Event().on('click', document, this.documentClickEvent.bind(this));
    }

    openSubmenu($subMenu) {
        this.subNavigationOpen($subMenu);
        this.changeMenuTitle($subMenu);
        this.setSubmenuPosition($subMenu);
    }

    closeSubmenu() {
        let $subMenuWrapper = this.navigation.find(Navigation.selectors.subMenuWrapperDropdownOpen);
        let $subMenu = $subMenuWrapper.find(Navigation.selectors.subMenu);
        this.navigation.removeClass(Navigation.classes.navbarSubmenuOpened);
        $subMenuWrapper.removeClass(Navigation.classes.subMenuWrapperDropdownOpen);
        this.changeSubmenuTitle($subMenu);
        this.removeSubmenuPosition($subMenu);
    }

    mobileNavToggleClick(event, bCloseAll=false) {
        if (Navigation.isMobileNavigation()) {
            if (bCloseAll == true) {
                $('body').removeClass(Navigation.classes.navbarMenuOpened);
                $('main').removeClass(Navigation.classes.navbarMenuOpened);
                $('footer').removeClass(Navigation.classes.navbarMenuOpened);

                this.navigation.find(Navigation.selectors.languageMenuButton).removeClass(Navigation.classes.languageMenuOpen);
                this.navigation.find(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
            } else {
                // Wenn Menü nicht offen, öffne Menü
                if (!this.navigation.is(Navigation.selectors.navbarMenuOpened)) {
                    this.firstLevel.collapse('show');
                    this.navigation.removeClass(Navigation.classes.navbarSubmenuOpened).addClass(Navigation.classes.navbarMenuOpened);
                    $('body').addClass(Navigation.classes.navbarMenuOpened);
                    $('main').addClass(Navigation.classes.navbarMenuOpened);
                    $('footer').addClass(Navigation.classes.navbarMenuOpened);

                    // Wenn Menü offen & Submenü offen, schließe Submenü
                } else if (this.navigation.is(Navigation.selectors.navbarSubmenuOpened)) {
                    this.closeSubmenu();

                    // Wenn Menü offen & Submenü nicht offen, schließe Menü
                } else {
                    this.firstLevel.collapse('hide');
                    this.navigation.removeClass(Navigation.classes.navbarMenuOpened);
                    this.navigation.find(Navigation.selectors.languageMenuButton).removeClass(Navigation.classes.languageMenuOpen);
                    this.navigation.find(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
                    $('body').removeClass(Navigation.classes.navbarMenuOpened);
                    $('main').removeClass(Navigation.classes.navbarMenuOpened);
                    $('footer').removeClass(Navigation.classes.navbarMenuOpened);
                }
            }
        } else {
            this.firstLevel.collapse('hide');
            this.navigation.removeClass(Navigation.classes.navbarMenuOpened);
            this.navigation.find(Navigation.selectors.languageMenuButton).removeClass(Navigation.classes.languageMenuOpen);
            this.navigation.find(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
            this.navigation.removeClass(Navigation.classes.navbarSubmenuOpened);
            this.navigation.find(Navigation.selectors.subMenuWrapperDropdownOpen).removeClass(Navigation.classes.subMenuWrapperDropdownOpen);
            $('body').removeClass(Navigation.classes.navbarMenuOpened);
            $('main').removeClass(Navigation.classes.navbarMenuOpened);
        }
    }

    subNavigationOpen($subMenu) {
        if (Navigation.isMobileNavigation()) {
            this.navigation.addClass(Navigation.classes.navbarSubmenuOpened);
            $subMenu.closest(Navigation.selectors.subMenuWrapper).addClass(Navigation.classes.subMenuWrapperDropdownOpen);
        }
    }

    closeMenu() {
        if (Navigation.isMobileNavigation()) {
            this.closeSubmenu();
            this.navigation.removeClass(Navigation.classes.navbarMenuOpened);
            this.navigation.find(Navigation.selectors.languageMenuButton).removeClass(Navigation.classes.languageMenuOpen);
            this.navigation.find(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
            $('body').removeClass(Navigation.classes.navbarMenuOpened);
            $('main').removeClass(Navigation.classes.navbarMenuOpened);
            $('footer').removeClass(Navigation.classes.navbarMenuOpened);
            this.firstLevel.collapse('hide');
        }
    }

    changeMenuTitle($subMenu) {
        let subMenuTitle = $subMenu.closest(Navigation.selectors.subMenuWrapper).children(Navigation.selectors.subMenuLink).text();
        let $navigationTitle = this.navigation.find('.navigation-menu-title');
        let $subNavigationTitle = this.navigation.find('.navigation-submenu-title');

        $navigationTitle.removeClass('show-title');
        $subNavigationTitle.text(subMenuTitle).addClass('show-title');
    }

    changeSubmenuTitle($subMenu) {
        let $navigationTitle = this.navigation.find('.navigation-menu-title');
        let $subNavigationTitle = this.navigation.find('.navigation-submenu-title');

        $subNavigationTitle.removeClass('show-title');
        $navigationTitle.addClass('show-title');
    }

    languageNavigationToggle(event) {
        event.stopPropagation();

        if (Navigation.isMobileNavigation()) {
            if ($(event.target).next(Navigation.selectors.languageMenu).hasClass(Navigation.classes.languageMenuOpen)) {
                $(event.target).removeClass(Navigation.classes.languageMenuOpen);
                $(event.target).next(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
            } else {
                $(event.target).addClass(Navigation.classes.languageMenuOpen);
                $(event.target).next(Navigation.selectors.languageMenu).addClass(Navigation.classes.languageMenuOpen);
            }
        } else {
            if ($(event.target).next(Navigation.selectors.languageMenu).hasClass(Navigation.classes.languageMenuOpen)) {
                $(event.target).removeClass(Navigation.classes.languageMenuOpen);
                $(event.target).next(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
                $('body').removeClass(Navigation.classes.navbarMenuOpened);
            } else {
                $(event.target).addClass(Navigation.classes.languageMenuOpen);
                $(event.target).next(Navigation.selectors.languageMenu).addClass(Navigation.classes.languageMenuOpen);
                $('body').addClass(Navigation.classes.navbarMenuOpened);

                this.navigation.find('.nav-link, .dropdown-item').on('click', function() {
                    $(event.target).removeClass(Navigation.classes.languageMenuOpen);
                    $(event.target).next(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
                    $('body').removeClass(Navigation.classes.navbarMenuOpened);
                });
            }
        }
    }

    languageNavigationClickEvent(event) {
        event.stopPropagation();
    }

    setSubmenuPosition($subMenu) {
        if (Navigation.isMobileNavigation()) {
            let topPosition = this.firstLevel[0].scrollTop;
            $subMenu.css('top', topPosition);
        }
    }

    removeSubmenuPosition($subMenu) {
        $subMenu.css('top', '');
    }

    onResize(event) {
        this.closeMenu();
    }

    documentClickEvent(event) {
        if($(Navigation.selectors.languageMenu).hasClass(Navigation.classes.languageMenuOpen)) {
            $('body').removeClass(Navigation.classes.navbarMenuOpened);
            $(Navigation.selectors.languageMenuButton).removeClass(Navigation.classes.languageMenuOpen);
            $(Navigation.selectors.languageMenu).removeClass(Navigation.classes.languageMenuOpen);
        }
    }
}
