class Event {
    /**
     * Whether the event is a "live" event meaning it is automagically assigned
     * to all new elements matching the selector
     *
     * @type {boolean}
     */
    isLive = false;

    /**
     * When true the event gets triggered after initialization
     * via .trigger('init')
     *
     * @type {boolean}
     */
    init = false;

    /**
     * The LIVE event will be bound to this jQuery element.
     *
     * @type {jQuery}
     */
    $bindObject = $(document);

    /**
     * Per default the namespace gets generated but it is possible to override
     * it via options.namespace.
     *
     * @type {string}
     */
    namespace = this._generatePseudoRandomNamespace();

    /**
     * Data to be passed to the handler in event.data when an event is triggered.
     *
     * @type {*}
     */
    data = null;

    /**
     * The concatenated full event namespace goes here
     *
     * @type {string}
     */
    eventNamespace = '';

    /**
     * This is the return value of addEvent() and is primarily used to
     * get rid of an event in the off() method
     *
     * @type {jQuery}
     */
    eventSelector = null;

    /**
     * Constructor
     */
    constructor() {}

    /**
     * See jQuerys .on method
     *
     * @param {string} eventName
     * @param {string|jQuery} selector
     * @param {function} handler callable
     * @param {object} options
     * @returns jQuery
     */
    on(eventName, selector, handler, options = null) {
        return this.addEvent('on', eventName, selector, handler, options);
    }

    /**
     * See jQuerys .one method
     *
     * @param {string} eventName
     * @param {string|jQuery} selector
     * @param {function} handler callable
     * @param {object} options
     */
    one(eventName, selector, handler, options = null) {
        return this.addEvent('one', eventName, selector, handler, options);
    }

    /**
     * Removes event
     *
     * @returns {jQuery}
     */
    off() {
        return this.eventSelector.off(this.eventNamespace);
    }

    /**
     * Adds an event with jQuerys {functionName} function
     *
     * @param {string} functionName
     * @param {string} eventName
     * @param {string|jQuery} selector
     * @param {function} handler
     * @param {object} options
     * @returns {jQuery}
     */
    addEvent(functionName, eventName, selector, handler, options = null) {
        this._processOptions(options);
        eventName = this._processEventName(eventName);

        let $return = this.isLive
            ? this.$bindObject[functionName](eventName, selector, this.data, handler)
            : $(selector)[functionName](eventName, this.data, handler);

        if (this.init) {
            $return.trigger('init.' + this.namespace);
        }

        this.eventSelector = $return;

        return $return;
    }

    /**
     * Processes given options and sets the class variables accordingly
     *
     * @param {object} options
     * @private
     */
    _processOptions(options) {
        if (options === null)               return;

        if ('live' in options)              this.isLive = !!options.live;
        if ('namespace' in options)         this.namespace = options.namespace;
        if ('init' in options)              this.init = !!options.init;
        if ('bindObject' in options)        this.$bindObject = options.bindObject;
        if ('data' in options)              this.data = options.data;
    }

    /**
     * Parses the given event name, adds the namespace and adds init to the
     * event name if this.init === true. Returns the new event name string
     *
     * @param {string} eventName
     * @returns {string}
     * @private
     */
    _processEventName(eventName) {
        eventName = eventName.split(' ').join('.' + this.namespace + ' ') + '.' + this.namespace;
        if (this.init) {
            eventName += ' init.' + this.namespace;
        }

        this.eventNamespace = eventName;
        return eventName;
    }

    /**
     * Generates a pseudo random namespace
     *
     * @returns {string}
     * @private
     */
    _generatePseudoRandomNamespace() {
        return Math.floor((1 + Math.random()) * 0x10000000).toString(16).substring(1);
    }
}