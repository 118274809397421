class ChartProvider {
    static classes = {

    };

    /**
     * @see ChartProvider.classes
     */
    static selectors = GeneralUtility.getSelectors(ChartProvider.classes);

    labelData = null;
    data = null;
    additionalData = null;

    constructor(labelData, data, additionalData = null) {
        this.labelData = labelData;
        this.data = data;
        this.additionalData = additionalData;

        this.initCharts();
    }

    initCharts() {
        this.initChart();
    }

    initChart() {
        throw "You must implement this method before usage";
    }

    labelCallback(tooltipItems) {
        let formatOptions = parseInt(tooltipItems.yLabel) == tooltipItems.yLabel ? {} : {style: 'currency', currency: 'EUR'};
        let Formatter = new Intl.NumberFormat('de-DE', formatOptions);

        return Formatter.format(tooltipItems.yLabel);
    }

    getDefaultOptions(index=null) {
        return {
            type: 'bar',
            data: {
                labels: this.labelData,
                datasets: this.getDataSetsOptions(index),
            },
            options: {
                layout: {
                    padding: {
                        top: 10,
                    },
                },
                legend: this.getLegendOptions(index),
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: this.getXAxesOptions(index),
                    yAxes: this.getYAxesOptions(index),
                },
                tooltips: {
                    intersect: false,
                    backgroundColor: '#231f20',
                    titleFontFamily: "'TupperPRO', Arial, sans-serif",
                    titleFontStyle: 'normal',
                    bodyFontFamily: "'TupperPRO', Arial, sans-serif",
                    bodyFontStyle: 'bold',
                    displayColors: false,
                    caretSize: 0,
                    caretPadding: 0,
                    cornerRadius: 10,
                    xAlign: "center",
                    yAlign: "center",
                    xPadding: 12,
                    yPadding: 4,
                    callbacks: {
                        title: function() {},
                        label: this.labelCallback,
                    },
                },
            },
        };
    }

    getDataSetsOptions(index=null) {
        return [];
    }

    getLegendOptions(index=null) {
        return false;
    }

    getXAxesOptions(index=null) {
        return [{
            gridLines: {
                display: false,
                drawBorder: false,
            },
            pointLabels: {
                fontFamily: "'TupperPRO', Arial, sans-serif"
            },
            ticks: {
                beginAtZero: true,
                fontColor: '#b6b6b6',
                fontFamily: "'TupperPRO', Arial, sans-serif",
                padding: 10
            },
            offset: true,
        }];
    }

    getYAxesOptions(index=null) {
        return [{
            gridLines: {
                display: false,
                drawBorder: true,
            },
            pointLabels: {
                fontFamily: "'TupperPRO', Arial, sans-serif"
            },
            ticks: {
                beginAtZero: true,
                display: true,
                fontColor: '#b6b6b6',
                fontFamily: "'TupperPRO', Arial, sans-serif"
            }
        }];
    }
}