class HorizontalNavigation {
    constructor($subNavigation) {
        this.$subNavigation = $subNavigation;
        this.$scrollNav = this.$subNavigation.find('.nav');
        $subNavigation.on('click', '.nav-link', function(clickEvent) {
            let clickOffsetLeft = clickEvent.target.offsetLeft;
            let centerPosition = ($(document).innerWidth() - $(clickEvent.target).innerWidth()) / 2;
            this.$scrollNav.animate({
                'scrollLeft': clickOffsetLeft - centerPosition
            });
        }.bind(this));
    }

}